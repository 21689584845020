<template>
    <div>


        <v-simple-table dense>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-center"><span class="body-2 font-weight-black"></span></th>
                        <th class="text-center"><span class="body-2 font-weight-black">Gasto fecal</span></th>
                        <th class="text-center"><span class="body-2 font-weight-black">Gasto urinario</span></th>
                        <th class="text-center"><span class="body-2 font-weight-black">Ingreso total</span></th>
                        <th class="text-center"><span class="body-2 font-weight-black">Egreso total</span></th>
                        <th class="text-center"><span class="body-2 font-weight-black">Balance</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="itm in turnos"
                        :key="itm.id"
                        class="mb-3"
                        :class="itm.color+' lighten-3'"
                    >
                        <td>
                            <span class="text-h6">{{itm.label}}</span>
                        </td>

                        <td class="text-center">
                            <span v-if='!itm.turno == "tot"'>{{gastoFecal[itm.turno].length>0?$numeral(gastoFecal[itm.turno][0].cantidad).format('0,0.00'):'-'}}</span>
                            <!--{{$numeral(gastoFecal[itm.turno]).format('0,0.00')}}-->
                        </td>
                        <td class="text-center">
                            <!--{{$numeral(gastoUrinario[itm.turno]).format('0,0.00')}}-->
                            <span v-if='!itm.turno == "tot"'>{{gastoUrinario[itm.turno].length>0?$numeral(gastoUrinario[itm.turno][0].cantidad).format('0,0.00'):'-'}}</span>
                        </td>
                        <td class="text-center">
                            <!--{{$numeral(totalIngresos[itm.turno]).format('0,0.00')}}-->
                            {{$numeral(ti(itm.turno)).format('0,0.00')}}
                        </td>
                        <td class="text-center">
                            <!--{{$numeral(totalEgresos[itm.turno]).format('0,0.00')}}-->
                            {{$numeral(te(itm.turno)).format('0,0.00')}}
                        </td>
                        <td class="text-center">
                            <!-- {{calculaSigno(balance[itm.turno])}} {{$numeral(Math.abs(balance[itm.turno])).format('0,0.00')}} -->
                            <span v-if='itm.turno != "tot"'>{{calculaSigno(balance[itm.turno])}} {{$numeral(Math.abs(balance[itm.turno])).format('0,0.00')}}</span>
                            <b><span v-if='itm.turno == "tot"'>{{calculaSigno(granTotal)}} {{$numeral(Math.abs(granTotal)).format('0,0.00')}}</span></b>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
export default {
    props:{
        estaFecha: String,
        internamiento: Number,
        totalIngresos:Object,
        totalEgresos:Object,

        gastoFecal:Object,
        gastoUrinario:Object,


    },
    data:()=>({
        turnos:[
        //.format('0,0.00')
            {id:0, label:'Matutino', turno:'mat', color:'blue'},
            {id:1, label:'Vespertino', turno:'ves', color:'green'},
            {id:2, label:'Nocturno', turno:'noc', color:'red'},
            {id:3, label:'Total (24 hrs)', turno:'tot', color:'orange'},
        ],

    }),
    methods:{
        calculaSigno(val){
            if (val >0){
                return '(+)'
            }
            if (val < 0){
                return '(-)'
            }
            if (val == 0){
                return ''
            }
        },
        ti(trn){
            if (trn != 'tot'){
                return this.totalIngresos[trn]
            } else {
                return this.totalIngresos.mat + this.totalIngresos.ves + this.totalIngresos.noc
            }
        },
        te(trn){
            if (trn != 'tot'){
                return this.totalEgresos[trn]
            } else {
                return this.totalEgresos.mat + this.totalEgresos.ves + this.totalEgresos.noc
            }
        }
    },
    computed:{
        totalDia(){
            //console.log(this.totalIngresos)
            //console.log(this.totalEgresos)
            let ingreso = this.totalIngresos.mat + this.totalIngresos.ves + this.totalIngresos.noc
            let egreso = this.totalEgresos.mat + this.totalEgresos.ves + this.totalEgresos.noc
            return ({ingreso:ingreso, egreso:egreso})
        },
        balance(){
            let mat = this.totalIngresos.mat - this.totalEgresos.mat
            let ves = this.totalIngresos.ves - this.totalEgresos.ves
            let noc = this.totalIngresos.noc - this.totalEgresos.noc
            let tot = (this.totalIngresos.mat + this.totalIngresos.ves + this.totalIngresos.noc)-(this.totalEgresos.mat + this.totalEgresos.ves + this.totalEgresos.noc)
            return ({mat:mat, ves:ves, noc:noc, total:tot})
        },
        granTotal(){
            return (this.totalIngresos.mat + this.totalIngresos.ves + this.totalIngresos.noc)-(this.totalEgresos.mat + this.totalEgresos.ves + this.totalEgresos.noc)
        },
    },

}
</script>

<style>

</style>