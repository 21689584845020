<template>
    <div>
        <v-simple-table
            fixed-header
            dense
            class="mt-5"
        >
            <template v-slot:default>

                <thead>
                    <tr >
                        <th :style="{'background-color': turnos[turno]}">
                            <v-btn
                                class="mr-3 my-2"
                                color="green"
                                dark
                                small
                                @click="nuevaEntrada('ingreso')"
                            >
                                <v-icon left>mdi-plus</v-icon>
                                Nuevo
                            </v-btn>
                            <div class="text-h6">Ingresos ({{$numeral(granTotal('ing')).format('0,0.00')}})</div>
                        </th>

                        <th
                            v-for="itm in horaList[turno]"
                            :key="itm.id"
                            width="5%"
                            :style="{'background-color': turnos[turno]}"
                        >
                            <div class="white--text">{{itm}}</div>
                        </th>
                        <th width="5%" :style="{'background-color': turnos[turno]}">
                        </th>
                    </tr>
                </thead>
                <tbody
                    v-for="item in ingresosItemsList"
                    :key="item"
                >
                    <tr
                        :style="{'background-color': turnos[turno]}"
                        class="white--text"
                    >
                        <td :colspan='horaList[turno].length + 2'>
                            <b>{{item}}</b>
                        </td>
                    </tr>

                    <tr
                        v-for="entrada in filtraEntradas(item)"
                        :key="entrada.id"
                    >
                        <td class="tablaBorde">
                            <span class="text-caption">{{entrada}}</span>
                        </td>
                        <td
                            v-for="itm in horaList[turno]"
                            :key="itm.id"
                            class="tablaBorde"
                        >
                            <div
                                v-for="liq in totalRegistroEntrada(entrada, itm)"
                                :key="liq.id"
                                class="text-center text-caption"
                                @click="abreDetalle(liq)"
                            >
                                <div :style="{'border-style': 'solid', 'border-width': '1px'}" class="my-1 pa-1 rounded-pill">{{$numeral(liq.cantidad).format('0,0.00')}}</div>
                            </div>
                        </td>
                        <td class="tablaBorde">
                            <div class="text-caption text-center font-weight-bold">{{ $numeral(totalRow(entrada, 'ing')).format('0,0.00') }}</div>
                        </td>
                    </tr>
                    <tr >
                        <td :colspan='horaList[turno].length + 1' class="tablaBorde">
                            <b>Total {{item}}</b>
                        </td>
                        <td class="tablaBorde">
                            <div class="font-weight-black text-center">
                                {{ $numeral(totalCat(item, 'ing')).format('0,0.00') }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>



        <v-simple-table
            fixed-header
            dense
            class="mt-5"
        >
            <template v-slot:default>

                <thead>
                    <tr>
                        <th :style="{'background-color': turnos[turno]}">
                            <v-btn
                                class="mr-3 my-2"
                                color="green"
                                dark
                                small
                                @click="nuevaEntrada('egreso')"
                            >
                                <v-icon left>mdi-plus</v-icon>
                                Nuevo
                            </v-btn>
                            <div class="text-h6">Egresos ({{$numeral(granTotal('egr')).format('0,0.00')}})</div>
                        </th>

                        <th
                            v-for="itm in horaList[turno]"
                            :key="itm.id"
                            width="5%"
                            :style="{'background-color': turnos[turno]}"
                        >
                            {{itm}}
                        </th>
                        <th width="5%" :style="{'background-color': turnos[turno]}">
                            
                        </th>
                    </tr>
                </thead>
                
                <tbody
                    v-for="item in egresosItemsList"
                    :key="item"
                >
                    <tr
                        :style="{'background-color': turnos[turno]}"
                        
                    >
                        <td :colspan='horaList[turno].length + 2'>
                            <b>{{item}}</b>
                        </td>
                        
                    </tr>

                    <tr
                        v-for="entrada in filtraSalidas(item)"
                        :key="entrada.id"
                    >
                        <td class="tablaBorde">
                            <span class="text-caption">{{entrada}}</span>
                        </td>
                        <td
                            v-for="itm in horaList[turno]"
                            :key="itm.id"
                            class="tablaBorde"
                        >
                            <div
                                v-for="liq in totalRegistroSalida(entrada, itm)"
                                :key="liq.id"
                                class="text-center text-caption"
                                @click="abreDetalle(liq)"
                            >
                                <div class="blue-grey lighten-3 my-1 pa-1 rounded-pill white--text">{{$numeral(liq.cantidad).format('0,0.00')}}</div>
                            </div>
                        </td>
                        <td class="tablaBorde">
                            <div class="text-caption text-center font-weight-bold">{{ $numeral(totalRow(entrada, 'egr')).format('0,0.00') }}</div>
                        </td>
                    </tr>
                    <tr>
                        <td :colspan='horaList[turno].length + 1' class="tablaBorde">
                            <b>Total {{item}}</b>
                        </td>
                        <td class="tablaBorde">
                            <div class="font-weight-black text-center">
                                {{ $numeral(totalCat(item, 'egr')).format('0,0.00') }}
                            </div>
                        </td>
                    </tr>
                </tbody>

                <tbody>
                    <tr>
                        <td :colspan='horaList[turno].length + 2'>
                        </td>
                    </tr>
                    <tr>
                        <td :colspan='horaList[turno].length' class="tablaBorde">
                            <span class="font-weight-black text-h6">GASTO FECAL</span>
                        </td>
                        <td class="tablaBorde" colspan=2>
                            <div v-if="fecal.length>0" class="text-center text-subtitle-2 blue-grey lighten-3 my-1 pa-1 rounded-pill white--text" @click="abreDetalleGasto(fecal)">
                                {{fecal.length>0?$numeral(fecal[0].cantidad).format('0,0.00'):'-'}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td :colspan='horaList[turno].length' class="tablaBorde">
                            <span class="font-weight-black text-h6">GASTO URINARIO</span>
                        </td>
                        <td class="tablaBorde" colspan=2>
                            <div v-if="urinario.length>0" class="text-center text-subtitle-2 blue-grey lighten-3 my-1 pa-1 rounded-pill white--text" @click="abreDetalleGasto(urinario)">
                                {{urinario.length>0?$numeral(urinario[0].cantidad).format('0,0.00'):'-'}}
                            </div>
                        </td>
                    </tr>
                </tbody>

            </template>
        </v-simple-table>

        <nueva-entrada
            :nuevaEntradaDialog.sync="nuevaEntradaDialog"
            :tipo="esteTipo"
            :turno="turno"
            :estaFecha="estaFecha"
            :internamiento="internamiento"
            @agregado="$emit('agregado')"
        />

        <detalle-entrada
            :nuevaEntradaDialog.sync="entradaDetalleDialog"
            :datos="estosDatos"
            @borrado="$emit('borrado')"
        />
    </div>
</template>

<script>
export default {
    components:{
        'nueva-entrada':()=>import('@/components/hospital/enfe/ingresos/NuevoIngresoEgreso'),
        'detalle-entrada':()=>import('@/components/hospital/enfe/ingresos/DetalleEntrada'),
    },
    props:{
        turno: String,
        estaFecha: String,
        internamiento: Number,
        ingresos:Array,
        egresos:Array,
        fecal:Array,
        urinario:Array,
    },
    data:()=>({
        muestraDetalles:false,
        nuevaEntradaDialog:false,
        entradaDetalleDialog:false,

        esteTipo:'',
        estosDatos:{},
        

        horaList:{
            mat:['08:00', '09:00','10:00','11:00','12:00','13:00','14:00'],
            ves:['15:00','16:00','17:00','18:00','19:00','20:00'],
            noc:['21:00','22:00','23:00','00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00'],
        },
        turnos:{
            mat:'#81D4FA',
            ves:'#A5D6A7',
            noc:'#EF9A9A',
        },

    }),
    methods:{
        abreDetalle(liq){
            //console.log(liq)
            this.estosDatos={}
            this.estosDatos = liq
            this.entradaDetalleDialog=true
        },
        abreDetalleGasto(itm){
            //console.log(itm)
            this.estosDatos={}
            this.estosDatos = itm[0]
            this.entradaDetalleDialog=true
        },

        filtraEntradas(item){
            let salida = []
            salida = this.ingresos.filter(ent=>ent.itemNombre==item)
            console.log(salida)

            salida = [... new Set(salida.map(a=>a.observaciones))]
            return salida
        },

        filtraSalidas(item){
            let salida = []
            //let salida2=[]
            salida = this.egresos.filter(ent=>ent.itemNombre==item)
            salida = [... new Set(salida.map(a=>a.itemNombre))]
            return salida
        },
        nuevaEntrada(tipo){
            this.esteTipo=tipo
            this.nuevaEntradaDialog=true
        },
        totalRegistroEntrada(txt, hora){
            // obtiene la hora
            let salida
            let soloHora = hora.split(':')[0]
            // obtiene los registros con la cadena
            salida = this.ingresos.filter(ent=>ent.observaciones==txt)
            salida = salida.filter(ent=>ent.hora.split(':')[0] == soloHora)
            return salida
        },
        totalRegistroSalida(txt, hora){
            // obtiene la hora
            let salida
            let soloHora = hora.split(':')[0]
            // obtiene los registros con la cadena
            salida = this.egresos.filter(ent=>ent.itemNombre==txt)
            salida = salida.filter(ent=>ent.hora.split(':')[0] == soloHora)
            return salida
        },
        totalRow(txt, tipo){
            let salida
            let valor = 0

            if (tipo == 'ing'){
                salida = this.ingresos.filter(ent=>ent.observaciones==txt)
            }

            if (tipo=='egr'){
                salida = this.egresos.filter(ent=>ent.itemNombre==txt)
            }
            valor = salida.map(itm=>itm.cantidad)
            return valor.reduce((a, b) => a + b, 0)
        },

        totalCat(txt, tipo){
            let valor = 0
            let salida
            if (tipo == 'ing'){
                salida = this.ingresos.filter(ent=>ent.itemNombre==txt)
            }

            if (tipo=='egr'){
                salida = this.egresos.filter(ent=>ent.itemNombre==txt)
            }
            valor = salida.map(itm=>itm.cantidad).reduce((a, b) => a + b, 0)
            return valor


        },
        granTotal(tipo){
            let total = 0
            if (tipo == 'ing'){
                total = this.ingresos.map(itm=>itm.cantidad).reduce((a, b) => a + b, 0)
            }

            if (tipo=='egr'){
                total = this.egresos.map(itm=>itm.cantidad).reduce((a, b) => a + b, 0)
            }
            return total
        },
    },

    computed:{
        //--------------------------------
        // LISTA LODOS LOS ITEMS
        // ------------------------------
        ingresosItemsList(){
            return [...new Set(this.ingresos.map(a => a.itemNombre))].sort()

        },
        egresosItemsList(){
            return [...new Set(this.egresos.map(a => a.itemNombre))].sort()

        },
    },
    

}
</script>

<style>
.tablaBorde{
    border: 1px solid  #ECECEC;
    border-spacing: 0.5rem;
    border-collapse: collapse;
}
</style>