<template>
    <div>
        <v-toolbar short dense color="primary" dark flat>
            <v-toolbar-title>Ingresos/Egresos</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn icon :loading="loadingInOut" @click="cargaTodosInOut">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
            
        </v-toolbar>

        <v-expansion-panels
            class="pa-3"
        >
            <v-expansion-panel
                v-for="itm in turnos"
                :key="itm.id"
            >
                <v-expansion-panel-header :color="itm.color+' lighten-3'">
                    <div class="d-flex justify-space-between">
                        <div>
                            <span class="text-h6">{{itm.label}}</span>
                        </div>


                        <div class="mr-3">
                            <span class="text-h6 white--text">
                                <span v-if="Math.abs(totalIngresos[itm.turno] - totalEgresos[itm.turno]) != 0">
                                    ({{calculaSigno(totalIngresos[itm.turno] - totalEgresos[itm.turno])}})
                                </span>
                                {{ $numeral(Math.abs(totalIngresos[itm.turno] - totalEgresos[itm.turno])).format('0,0.00') }}
                            </span>
                        </div>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content
                    :class="itm.color+' lighten-5'"
                >
                    <ingreso-detalle
                        :turno="itm.turno"
                        :estaFecha="estaFecha"
                        :internamiento="internamiento"
                        :ingresos="ingresos[itm.turno]"
                        :egresos="egresos[itm.turno]"
                        :fecal="fecal[itm.turno]"
                        :urinario="urinario[itm.turno]"
                        @agregado="cargaTodosInOut"
                        @borrado="cargaTodosInOut"
                    />
                </v-expansion-panel-content>
            </v-expansion-panel>



            <v-expansion-panel>
                <v-expansion-panel-header color='orange lighten-3'>
                    <div class="d-flex justify-space-between">
                        <div>
                            <span class="text-h6">BALANCE TOTAL (24 horas)</span>
                        </div>


                        <div class="mr-3">
                            <span class="text-h6 white--text">

                                <span class="text-h6 white--text">
                                    <span v-if="Math.abs(granTotal) != 0">
                                        ({{calculaSigno(granTotal)}})
                                    </span>
                                    {{ $numeral(Math.abs(granTotal)).format('0,0.00') }}
                                </span>
                            </span>
                        </div>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <ingreso-total
                        :estaFecha="estaFecha"
                        :internamiento="internamiento"
                        :totalIngresos="totalIngresos"
                        :totalEgresos="totalEgresos"

                        :gastoFecal="fecal"
                        :gastoUrinario="urinario"

                    />
                </v-expansion-panel-content>
            </v-expansion-panel>

        </v-expansion-panels>

    </div>
</template>

<script>
import IngresoDetalle from '@/components/hospital/enfe/ingresos/IngresoDetalle'
import IngresoTotal from '@/components/hospital/enfe/ingresos/IngresoTotal'
export default {
    mounted(){
        this.cargaTodosInOut()
    },
    props:{
        estaFecha:String,
        internamiento:Number,
    },
    components:{
        'ingreso-detalle':IngresoDetalle,
        'ingreso-total':IngresoTotal
    },
    data:()=>({
        loadingInOut:false,
        
        totalIngresos:{
            mat:0, ves:0, noc:0,
        },
        totalEgresos:{
            mat:0, ves:0, noc:0,
        },
        ingresos:{
            mat:[], ves:[], noc:[], tot:[],
        },
        egresos:{
            mat:[], ves:[], noc:[], tot:[],
        },
        fecal:{
            mat:[], ves:[], noc:[], tot:[],
        },
        urinario:{
            mat:[], ves:[], noc:[], tot:[],
        },
        

        turnos:[
            {id:0, label: 'Matutino', turno:'mat', color:'light-blue'},
            {id:1, label: 'Vespertino', turno:'ves', color:'green'},
            {id:2, label: 'Nocturno', turno:'noc', color:'red'},
        ],

    }),
    methods:{

        calculaSigno(val){
            if (val >0){
                return '+'
            }
            if (val < 0){
                return '-'
            }
            if (val == 0){
                return ''
            }
        },
        
        cargaTodosInOut(){
            this.cargaInOut('mat')
            this.cargaInOut('ves')
            this.cargaInOut('noc')
            this.cargaInOut('tot')
        },
        async cargaInOut(turno){
            this.loadingInOut=true

            this.totalIngresos[turno] = 0
            this.totalEgresos[turno] = 0

            let params = {
                internamiento:this.internamiento,
                fecha: this.estaFecha,
                turno:turno
            }

            try{
                let io = await this.$http({
                    url:'/enfermeria/listIngresosEgresos',
                    method:'GET',
                    params:params
                })


                this.ingresos[turno]= io.data.ingresos
                this.egresos[turno]= io.data.egresos

                this.fecal[turno]= io.data.fecal
                this.urinario[turno]= io.data.urinario

                this.totalIngresos[turno] = io.data.totalIngresos
                this.totalEgresos[turno] = io.data.totalEgresos

                this.loadingInOut=false
            }catch(err){
                this.loadingInOut=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
    },
    computed:{
        granTotal(){
            let ingresos = Object.values(this.totalIngresos).reduce((a, b) => a + b, 0)
            let egresos = Object.values(this.totalEgresos).reduce((a, b) => a + b, 0)

            return ingresos-egresos

        },
    },
    watch:{
        estaFecha(){
            this.cargaTodosInOut()
        }
    },
}
</script>

<style>

</style>